import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetComponentStyle from '~/src/hooks/layout/useGetComponentStyle'
import StylesDeliveryMethodsSummaryCardServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-card/styles/StylesDeliveryMethodsSummaryCardServiceInterface'
import getDeliveryMethodsSummaryCardTemplateService
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-card/styles/GetDeliveryMethodsSummaryCardTemplateService'

const useGetDeliveryMethodsSummaryCardTemplate = (): StylesDeliveryMethodsSummaryCardServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const componentStyle = useGetComponentStyle('deliveryMethodsSummary')

  return getDeliveryMethodsSummaryCardTemplateService(componentStyle, muiTheme)
}

export default useGetDeliveryMethodsSummaryCardTemplate
