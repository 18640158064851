import ShoppingCart from '~/src/components/cart/shopping-cart'
import useGetShoppingCartContainerTemplate
  from '~/src/components/cart/shopping-cart-container/styles/useGetShoppingCartContainerTemplate'
import Card from '~/src/components/mui-wrappers/Card'
import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'

const OrderSummary = () => {
  const { t } = useTranslations()
  const styles = useGetShoppingCartContainerTemplate()

  return (
    <>
      <Title
        variant='h6'
        sx={{
          pb: 2,
          color: 'background.contrastText'
        }}
      >
        {t('order.summary')}
      </Title>
      <Card sx={styles.getCardSxProps()}>
        <ShoppingCart
          showActions={false}
          showQuantitySelector={false}
          showDeleteButton={false}
          showQuantityAsText
        />
      </Card>
    </>
  )
}

export default OrderSummary
