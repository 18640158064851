import { useLayoutEffect, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'
import useGetDOMNodeProperties from '~/src/hooks/layout/useGetDOMNodeProperties'

interface ReturnsType {
  offset: number,
  getOffset(includeHeader?: boolean, includeSubheader?: boolean): number,
}

const useGetMainSideBarOffset = (): ReturnsType => {
  const { header, subHeader } = useEoValue(LAYOUT_STATE)
  const [ offset, setOffset ] = useState<number>(0)
  const { height: headerHeight } = useGetDOMNodeProperties('layout_header')
  const { height: subHeaderHeight } = useGetDOMNodeProperties('layout_sub_header')

  const getOffset = (includeHeader = true, includeSubheader = true): number => {
    let calculatedOffset = 0

    if (includeHeader) {
      calculatedOffset += headerHeight
    }

    if (includeSubheader) {
      calculatedOffset += subHeaderHeight
    }

    return Math.round((calculatedOffset + Number.EPSILON) * 100) / 99
  }

  useLayoutEffect(() => {
    setOffset(getOffset())
  }, [ header, headerHeight, subHeader, subHeaderHeight ])

  return {
    offset,
    getOffset
  }
}

export default useGetMainSideBarOffset
