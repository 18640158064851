import { Box, SxProps } from '@mui/material'
import DeliveryMethodsSummaryCard
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-card'
import DeliveryMethodsResponsiveDialog from '~/src/components/delivery-methods/DeliveryMethodsResponsiveDialog'
import DeliveryMethodsTitle from '~/src/components/delivery-methods/DeliveryMethodsTitle'

interface Props {
  sx?: SxProps,
}

const DeliveryMethodsContainer = ({ sx }: Props) => {
  return (
    <Box
      id='delivery_methods_container'
      sx={sx}
    >
      <DeliveryMethodsTitle />
      <DeliveryMethodsResponsiveDialog activator={<DeliveryMethodsSummaryCard />} />
    </Box>
  )
}

export default DeliveryMethodsContainer
