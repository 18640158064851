import getMainSideBarTemplateService from '~/src/components/layout/main-side-bar/styles/GetMainSideBarTemplateService'
import StylesMainSideBarServiceInterface
  from '~/src/components/layout/main-side-bar/styles/StylesMainSideBarServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetMainSideBarTemplate = (): StylesMainSideBarServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()
  const layoutState = useEoValue(LAYOUT_STATE)

  return getMainSideBarTemplateService(pageStyle, muiTheme, layoutState)
}

export default useGetMainSideBarTemplate
