import getShoppingCartContainerTemplateService
  from '~/src/components/cart/shopping-cart-container/styles/GetShoppingCartContainerTemplateService'
import StylesShoppingCartContainerServiceInterface
  from '~/src/components/cart/shopping-cart-container/styles/StylesShoppingCartContainerServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetShoppingCartContainerTemplate = (): StylesShoppingCartContainerServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getShoppingCartContainerTemplateService(pageStyle, muiTheme)
}

export default useGetShoppingCartContainerTemplate
