import StylesMainSideBarServiceInterface
  from '~/src/components/layout/main-side-bar/styles/StylesMainSideBarServiceInterface'
import StylesMainSideBarServiceStyleOne
  from '~/src/components/layout/main-side-bar/styles/StylesMainSideBarServiceStyleOne'
import StylesMainSideBarServiceStyleTwo
  from '~/src/components/layout/main-side-bar/styles/StylesMainSideBarServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import { LayoutState } from '~/src/stores/layout'

const getMainSideBarTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme, layout?: LayoutState): StylesMainSideBarServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesMainSideBarServiceStyleTwo(muiTheme, undefined, layout)
    default:
      return new StylesMainSideBarServiceStyleOne(muiTheme, undefined, layout)
  }
}

export default getMainSideBarTemplateService
