import Card from '~/src/components/mui-wrappers/Card'
import DeliveryMethodsSummaryContainer
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-container'
import useGetDeliveryMethodsSummaryCardTemplate
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-card/styles/useGetDeliveryMethodsSummaryCardTemplate'

const DeliveryMethodsSummaryCard = () => {
  const styles = useGetDeliveryMethodsSummaryCardTemplate()

  return (
    <Card {...styles.getCardProps()}>
      <DeliveryMethodsSummaryContainer/>
    </Card>
  )
}

export default DeliveryMethodsSummaryCard
