import { paperClasses, SxProps } from '@mui/material'
import StylesMainSideBarServiceInterface
  from '~/src/components/layout/main-side-bar/styles/StylesMainSideBarServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesMainSideBarServiceStyleOne extends TemplateServiceStyleBase implements StylesMainSideBarServiceInterface {
  public getBoxSx(getMaxHeight: CallableFunction, getOffset: CallableFunction): SxProps {
    const hasHeader = !!this.layout?.header.display
    const hasSubHeader = !!this.layout?.subHeader.display
    const hasFooter = !!this.layout?.footer.display

    return {
      position: 'sticky',
      height: `calc(100vh - ${getMaxHeight(hasHeader, hasSubHeader, hasFooter)}px)`,
      top: `${getOffset(hasHeader, hasSubHeader)}px`,
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      p: 3,
      [`div.${paperClasses.root}`]: {
        boxShadow: this.muiTheme.elevation
      },
      [this.muiTheme.breakpoints.down('md')]: {
        display: 'none'
      }
    }
  }
}

