import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesDeliveryMethodsSummaryCardServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-card/styles/StylesDeliveryMethodsSummaryCardServiceInterface'
import { CardProps, SxProps } from '@mui/material'

export default class StylesDeliveryMethodsSummaryCardServiceStyleOne extends TemplateServiceStyleBase implements StylesDeliveryMethodsSummaryCardServiceInterface {
  getCardProps(): CardProps {
    return {
      sx: this.getCardSx()
    }
  }

  getCardSx(): SxProps {
    return { mt: 2 }
  }
}
