import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesDeliveryMethodsSummaryCardServiceInterface
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-card/styles/StylesDeliveryMethodsSummaryCardServiceInterface'
import StylesDeliveryMethodsSummaryCardServiceStyleTwo
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-card/styles/StylesDeliveryMethodsSummaryCardServiceStyleTwo'
import StylesDeliveryMethodsSummaryCardServiceStyleOne
  from '~/src/components/delivery-methods/delivery-methods-summary/delivery-methods-summary-card/styles/StylesDeliveryMethodsSummaryCardServiceStyleOne'

const getDeliveryMethodsSummaryCardTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesDeliveryMethodsSummaryCardServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesDeliveryMethodsSummaryCardServiceStyleTwo(muiTheme)
    default:
      return new StylesDeliveryMethodsSummaryCardServiceStyleOne(muiTheme)
  }
}

export default getDeliveryMethodsSummaryCardTemplateService
