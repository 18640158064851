import { useCallback, useEffect, useState } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'
import useGetDOMNodeProperties from '~/src/hooks/layout/useGetDOMNodeProperties'

interface ReturnsType {
  maxHeight: number,
  getMaxHeight(includeHeader?: boolean, includeSubheader?: boolean, includeFooter?: boolean): number,
}

const useGetMainSideBarMaxHeight = (): ReturnsType => {
  const layout = useEoValue(LAYOUT_STATE)
  const [ maxHeight, setMaxHeight ] = useState<number>(0)
  const { height: headerHeight } = useGetDOMNodeProperties('layout_header')
  const { height: subHeaderHeight } = useGetDOMNodeProperties('layout_sub_header')
  const { height: footerHeight } = useGetDOMNodeProperties('layout_footer')

  const getMaxHeight = useCallback((includeHeader = true, includeSubheader = true, includeFooter = true): number => {
    let calculatedMaxHeight = 0

    if (includeHeader) {
      calculatedMaxHeight += headerHeight
    }

    if (includeSubheader) {
      calculatedMaxHeight += subHeaderHeight
    }

    if (includeFooter) {
      calculatedMaxHeight += footerHeight
    }

    return Math.round((calculatedMaxHeight + Number.EPSILON) * 100) / 99
  }, [ layout, headerHeight, subHeaderHeight, footerHeight ])

  useEffect(() => {
    setMaxHeight(getMaxHeight())
  }, [
    layout.header,
    headerHeight,
    layout.subHeader,
    subHeaderHeight,
    layout.footer,
    footerHeight
  ])

  return {
    maxHeight,
    getMaxHeight
  }
}

export default useGetMainSideBarMaxHeight
