import ShoppingCart from '~/src/components/cart/shopping-cart'
import useGetShoppingCartContainerTemplate
  from '~/src/components/cart/shopping-cart-container/styles/useGetShoppingCartContainerTemplate'
import Card from '~/src/components/mui-wrappers/Card'
import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'
import ShoppingCartProps from '~/src/types/ShoppingCartProps'

interface Props {
  ShoppingCartProps?: ShoppingCartProps,
}

const ShoppingCartContainer = (props: Props) => {
  const styles = useGetShoppingCartContainerTemplate()
  const { t } = useTranslations()

  return (
    <>
      <Title
        variant='h6'
        sx={{
          pb: 2,
          color: 'background.contrastText'
        }}
      >
        {t('shopping_cart.title')}
      </Title>
      <Card sx={styles.getCardSxProps()}>
        <ShoppingCart
          showSubTotal={false}
          showDeliveryMethodsResume
          {...props.ShoppingCartProps}
        />
      </Card>
    </>
  )
}

export default ShoppingCartContainer
