import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesShoppingCartContainerServiceInterface
  from '~/src/components/cart/shopping-cart-container/styles/StylesShoppingCartContainerServiceInterface'
import StylesShoppingCartContainerServiceStyles
  from '~/src/components/cart/shopping-cart-container/styles/StylesShoppingCartContainerServiceStyles'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const getShoppingCartContainerTemplateService = (style: TemplateEnum | null | undefined, muiTheme: CustomMuiTheme): StylesShoppingCartContainerServiceInterface => {
  return new StylesShoppingCartContainerServiceStyles(muiTheme)
}

export default getShoppingCartContainerTemplateService
